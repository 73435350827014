var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('b-card-header',{attrs:{"header-class":"pt-0"}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center w-100"},[_c('h4',[_vm._v("تفاصيل الدروس "+_vm._s(_vm.lessonDetailsDto.name))]),_c('div',[_c('b-form-radio-group',{attrs:{"id":"radio-group-2","name":"radio-sub-component"},model:{value:(_vm.lessonDetailsDto.isFree),callback:function ($$v) {_vm.$set(_vm.lessonDetailsDto, "isFree", $$v)},expression:"lessonDetailsDto.isFree"}},[_c('b-form-radio',{attrs:{"value":true}},[_vm._v("مجاني")]),_c('b-form-radio',{attrs:{"value":false}},[_vm._v("مدفوع")])],1)],1)])]),_c('b-card-body',[_c('ValidationObserver',{ref:"form"},[_c('b-row',[_c('b-col',{attrs:{"lg":"4"}},[_c('ek-input-text',{attrs:{"name":"name","label":" اسم الدروس ","placeholder":"ادخل اسم الدروس"},model:{value:(_vm.lessonDetailsDto.name),callback:function ($$v) {_vm.$set(_vm.lessonDetailsDto, "name", $$v)},expression:"lessonDetailsDto.name"}}),_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"lg":"6","md":"12"}},[_c('ek-input-select',{attrs:{"options":_vm.years,"placeholder":"السنة","name":" اختر السنة","label":"السنة","rules":[
                                        {
                                            type: 'required',
                                            message: 'السنة مطلوبة',
                                        } ]},model:{value:(_vm.lessonDetailsDto.yearId),callback:function ($$v) {_vm.$set(_vm.lessonDetailsDto, "yearId", $$v)},expression:"lessonDetailsDto.yearId"}})],1),_c('b-col',{staticClass:"pl-2",attrs:{"lg":"6","md":"12"}},[_c('ek-input-select',{attrs:{"options":_vm.semesters,"placeholder":"اختر الفصل","name":" اختر الفصل","label":"الفصل","rules":[
                                        {
                                            type: 'required',
                                            message: 'الفصل مطلوب',
                                        } ]},model:{value:(_vm.lessonDetailsDto.semesterId),callback:function ($$v) {_vm.$set(_vm.lessonDetailsDto, "semesterId", $$v)},expression:"lessonDetailsDto.semesterId"}})],1)],1),_c('ek-input-text',{attrs:{"name":"name","label":" ترتيب الدروس ","placeholder":"ترتيب الدروس"},model:{value:(_vm.lessonDetailsDto.order),callback:function ($$v) {_vm.$set(_vm.lessonDetailsDto, "order", $$v)},expression:"lessonDetailsDto.order"}})],1),_c('b-col',{attrs:{"lg":"4"}},[_c('ek-input-select',{attrs:{"name":"الفرع","disabled":"","label":" تابع ل جامعة - كلية - فرع ","placeholder":"جامعة - كلية - فرع","options":_vm.departmentsSubjectsOptions},on:{"change":_vm.resetCourse},model:{value:(_vm.lessonDetailsDto.departmentId),callback:function ($$v) {_vm.$set(_vm.lessonDetailsDto, "departmentId", $$v)},expression:"lessonDetailsDto.departmentId"}}),(
                                _vm.lessonDetailsDto.departmentId &&
                                _vm.universityNameCourse.find(
                                    function (un) { return un.id ==
                                        _vm.lessonDetailsDto.departmentId; }
                                )
                            )?_c('ek-input-select',{attrs:{"name":"المادة","label":" تابع ل سنة - فصل -كورس- مادة ","placeholder":"تابع ل سنة - فصل -كورس- مادة ","disabled":"","options":_vm.universityNameCourse.find(
                                    function (un) { return un.id ==
                                        _vm.lessonDetailsDto.departmentId; }
                                ).courses},model:{value:(_vm.lessonDetailsDto.courseId),callback:function ($$v) {_vm.$set(_vm.lessonDetailsDto, "courseId", $$v)},expression:"lessonDetailsDto.courseId"}}):_vm._e(),_c('ek-input-textarea',{attrs:{"name":"name","label":"شرح الدروس","placeholder":"شرح الدروس"},model:{value:(_vm.lessonDetailsDto.description),callback:function ($$v) {_vm.$set(_vm.lessonDetailsDto, "description", $$v)},expression:"lessonDetailsDto.description"}})],1),_c('b-col',{attrs:{"lg":"4"}},[_c('div',{staticClass:"position-relative"},[_c('b-dropdown',{staticClass:"position-absolute",staticStyle:{"z-index":"999"},attrs:{"size":"lg","variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"rounded-circle"},[_c('unicon',{attrs:{"name":"ellipsis-v","fill":"#005baa"}})],1)]},proxy:true}])},[_c('b-dropdown-item',{ref:"myUploader",attrs:{"href":"#"},on:{"click":function($event){return _vm.fileWindowApi(_vm.storeFileThumb)}}},[_c('unicon',{staticStyle:{"width":"25px"},attrs:{"name":"edit-alt","fill":"#005baa"}}),_c('span',[_vm._v("تعديل ملف")])],1)],1),_c('div',{staticClass:"w-100",staticStyle:{"height":"280px"}},[_c('img',{staticClass:"rounded w-100 h-100",staticStyle:{"object-fit":"cover"},attrs:{"src":_vm.$store.getters['app/domainHost'] +
                                        '/' +
                                        _vm.lessonDetailsDto.url.thumbUrl,"alt":""}})])],1),(_vm.isLoading)?[_c('div',{staticClass:"p-2"},[_c('span',{staticClass:"text-primary"},[_vm._v("جاري رفع الملف ")]),_c('b-progress',{staticClass:"mt-1",attrs:{"value":_vm.uploadProgress,"max":100,"show-progress":"","animated":""}})],1)]:_vm._e(),(!_vm.filesUploaded)?_c('b-button',{staticClass:"m-1",attrs:{"variant":"primary"},on:{"click":_vm.onUploadFile}},[_vm._v("رفع الملف")]):_vm._e(),_c('br'),(!_vm.filesUploaded)?_c('p',{staticClass:"my-1 text-warning"},[_vm._v(" يجب رفع الملف الجديد ليتم حفظه ")]):_vm._e(),_c('div',{staticClass:"mt-1 position-relative"},[(
                                    _vm.lessonDetailsDto.lessonType === 'Video'
                                )?_c('div',{staticClass:"w-100",staticStyle:{"height":"280px"}},[(_vm.lessonDetailsDto.duration)?_c('div',{staticClass:"position-absolute text-white bg-primary",style:({
                                        'z-index': 999,
                                        top: '10px',
                                        right: '9px',
                                        padding: '6px',
                                        'border-radius': '15px',
                                    })},[_c('span',{staticClass:"mr-[2px]",staticStyle:{"font-weight":"bold"}},[_vm._v(" ثانية ")]),_c('span',[_vm._v(" "+_vm._s(_vm.lessonDetailsDto.duration)+" ")])]):_vm._e(),_c('b-dropdown',{staticClass:"position-absolute",staticStyle:{"z-index":"999"},attrs:{"size":"lg","variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"rounded-circle"},[_c('unicon',{attrs:{"name":"ellipsis-v","fill":"#005baa"}})],1)]},proxy:true}],null,false,2942839113)},[_c('b-dropdown-item',{ref:"myUploader",attrs:{"href":"#"},on:{"click":function($event){return _vm.fileWindowApi(_vm.storeFile)}}},[_c('unicon',{staticStyle:{"width":"25px"},attrs:{"name":"edit-alt","fill":"#005baa"}}),_c('span',[_vm._v("تعديل ملف")])],1)],1),(_vm.newFileUrl && _vm.isDestroyVideo)?_c('video',{staticClass:"h-100 w-100 rounded",staticStyle:{"object-fit":"cover"},attrs:{"controls":"","autoplay":""}},[_c('source',{attrs:{"src":_vm.newFileUrl,"type":"video/mp4"}})]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"w-100 d-flex justify-content-center align-items-center"},[(
                                        _vm.lessonDetailsDto.duration &&
                                        _vm.lessonDetailsDto.lessonType ===
                                            'Document'
                                    )?_c('div',{staticClass:"position-absolute text-white bg-primary",style:({
                                        'z-index': 999,
                                        top: '10px',
                                        right: '0',
                                        padding: '6px',
                                        'border-radius': '15px',
                                    })},[_c('div',{staticClass:"flex flex-row-reverse"},[_c('span',{staticStyle:{"margin-right":"-18px"}},[_vm._v(" "+_vm._s(( _vm.lessonDetailsDto.duration / 1024 ).toFixed(2))+" ")]),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("KB")])])]):_vm._e(),_c('a',{attrs:{"href":_vm.$store.getters['app/domainHost'] +
                                        '/' +
                                        _vm.lessonDetailsDto.url.url,"target":"_blank"}},[(
                                            _vm.lessonDetailsDto.lessonType ===
                                            'Document'
                                        )?_c('img',{staticClass:"rounded",staticStyle:{"cursor":"pointer","object-fit":"cover","width":"250px"},attrs:{"src":"\n                            https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png    \n                            ","alt":""}}):_vm._e()])])])],2)],1)],1)],1)],1),(
            _vm.getUserRoles() == _vm.Admin ||
            _vm.getUserRoles() == _vm.SuperAdmin ||
            _vm.getUserRoles() == _vm.DataEntry
        )?_c('div',{staticClass:"d-flex justify-content-end align-items-center w-100"},[_c('AddQuestion',{staticClass:"w-50"})],1):_vm._e(),(
            _vm.getUserRoles() == _vm.Admin ||
            _vm.getUserRoles() == _vm.SuperAdmin ||
            _vm.getUserRoles() == _vm.DataEntry
        )?_c('div',[_c('span',[_c('div',{staticClass:"d-flex"},[_c('unicon',{staticClass:"pr-1",attrs:{"fill":"gray","name":"bookmark"}}),_c('h4',[_vm._v(" محتوى الدرس ("+_vm._s(_vm.lessonDetailsDto.question.length)+") سؤال ")])],1)]),_c('ek-table',{staticClass:"mt-2",attrs:{"columns":_vm.columnsLessons,"items":_vm.lessonDetailsDto.question},on:{"details":_vm.detailsLesson,"delete-selected":_vm.deleteQuestion},scopedSlots:_vm._u([{key:"items.dateCreated",fn:function(ref){
        var value = ref.value;
return [_vm._v(" "+_vm._s(new Date(value).toLocaleDateString())+" ")]}}],null,false,3296391026)})],1):_vm._e(),(_vm.getUserRoles() == _vm.Teacher)?_c('div',[_c('ek-table',{staticClass:"mt-2",attrs:{"columns":_vm.columnsLessons,"items":_vm.lessonDetailsDto.question},on:{"details":_vm.detailsLesson},scopedSlots:_vm._u([{key:"table-header",fn:function(){return [_c('div',{staticClass:"d-flex pt-1 pb-1"},[_c('unicon',{staticClass:"pr-1",attrs:{"fill":"gray","name":"bookmark"}}),_c('h4',[_vm._v(" محتوى الدرس ("+_vm._s(_vm.lessonDetailsDto.question.length)+") سؤال ")])],1)]},proxy:true},{key:"items.dateCreated",fn:function(ref){
        var value = ref.value;
return [_vm._v(" "+_vm._s(new Date(value).toLocaleDateString())+" ")]}}],null,false,1420538640)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }